import { useAuthContext } from "./useAuthContext"
import { useCharactersContext } from "./useCharactersContext"

export const useLogout = () => {
    const { dispatch } = useAuthContext()
    const { dispatch: charactersDispatch } = useCharactersContext()
    
    const logout = () => {
        //remove user from storage
        localStorage.removeItem('user')

        //dispatch logout action
        dispatch({type: 'LOGOUT'})
        charactersDispatch({type: 'SET_CHARACTERS', payload: null})
    }

    return {logout}
}