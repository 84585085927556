import { useCharactersContext } from '../hooks/useCharactersContext'
import { useAuthContext } from '../hooks/useAuthContext'
import { Link } from 'react-router-dom'
import { useState } from 'react';

//Date FNS
//import formatDistanceToNow from 'date-fns/formatDistanceToNow'

const CharacterCard = ({ character }) => {
    const { dispatch } = useCharactersContext()
    const { user } = useAuthContext()
    const [showStats, setShowStats] = useState(false) // Toggle stats visibility

    
    const deleteCharacter = async () => {
        if (!user) {
            return
        }

        const confirmed = window.confirm(`Are you sure you want to delete ${character.characterName}? This action cannot be undone.`)

        if (confirmed) {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/api/characters/` + character._id, {
                method: 'DELETE',
                headers: {
                    'Authorization': `Bearer ${user.token}`
                }
            })
            const json = await response.json()

            if (response.ok) {
                dispatch({type: 'DELETE_CHARACTER', payload: json})
            }
        }
    }

    // Handler for toggling the dropdown
    const toggleStatsVisibility = () => {
        setShowStats(prev => !prev);
    }


    return (
        <div className="card">
            <Link to={`/character/${character._id}`}>
                <h2>{character.characterName}</h2>
            </Link>

            <span className="material-symbols-outlined" onClick={deleteCharacter}>delete</span>

            <div className="divider">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 350 30">

                    <circle cx="175" cy="15" r="5" fill="#222" />
                

                    <polygon points="0,15 165,10 165,20" fill="#222" />
                

                    <polygon points="350,15 185,10 185,20" fill="#222" />
                </svg>
            </div>

            <div className="attribute">
                <div className="attribute-left">
                    <span className="label">Race:</span> <span>Half-Elf</span>
                </div>
                <div className="attribute-right">
                    <span className="label">Class:</span> <span>Sorcerer</span>
                </div>
            </div>
            
            <div className="attribute">
                <div className="attribute-left">
                    <span className="label">Level:</span> <span>{character.level}</span>
                </div>
                <div className="attribute-right">
                    <span className="label">HP:</span> <span>{character.health}</span>
                </div>
            </div>

            <div className="attribute">
                <div className="attribute-left">
                    <span className="label">AC:</span> <span>18</span>
                </div>
                <div className="attribute-right">
                    <span className="label">Speed:</span> <span>30 ft</span>
                </div>
            </div>


            {/* Dropdown Toggle Button */}
            <div className='dropdown-wrapper'>
                <button className="dropdown-button" onClick={toggleStatsVisibility}>
                    {showStats ? (
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            width="24"
                            height="24">
                            <polyline points="6 15 12 9 18 15" />
                        </svg>
                    ) : (
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            width="24"
                            height="24">
                            <polyline points="6 9 12 15 18 9" />
                        </svg>
                    )}
                </button>
            </div>

            {/* Only show stats when the dropdown is active */}
            {showStats && (
                <div className="stats-section">
                    <div className="attribute">
                        <div className="attribute-left">
                            <span className="label">Str:</span> <span>14 (+2)</span>
                        </div>
                        <div className="attribute-right">
                            <span className="label">Dex:</span> <span>16 (+3)</span>
                        </div>
                    </div>

                    <div className="attribute">
                        <div className="attribute-left">
                            <span className="label">Con:</span> <span>15 (+2)</span>
                        </div>
                        <div className="attribute-right">
                            <span className="label">Int:</span> <span>12 (+1)</span>
                        </div>
                    </div>

                    <div className="attribute">
                        <div className="attribute-left">
                            <span className="label">Wis:</span> <span>10 (+0)</span>
                        </div>
                        <div className="attribute-right">
                            <span className="label">Cha:</span> <span>18 (+4)</span>
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
}

export default CharacterCard