import { useState } from 'react'
import { useLogin } from '../hooks/useLogin'
import { Link } from 'react-router-dom'

const Login = () => {
    const [username, setUsername] = useState('')
    const [password, setPassword] = useState('')
    const {login, isLoading, error} = useLogin()

    const handleSubmit = async (e) => {
        e.preventDefault()

        await login(username, password)
    }

    return (
        <div className='centre'>
            <div className='login'>
                <form onSubmit={handleSubmit}>
                    <h3>Log In</h3>

                    <input type="username" placeholder='Username' onChange={(e) => setUsername(e.target.value)} value={username}/>
                    <input type="password"placeholder='Password' onChange={(e) => setPassword(e.target.value)} value={password}/>

                    <button disabled={isLoading}>Log In</button>
                    {error && <div className='error'>{error}</div>}
                </form>

                <p>Don't have an account <Link to="/signup">Sign Up!</Link></p>
            </div>
        </div>
    )
}

export default Login