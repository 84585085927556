import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom'
import { useAuthContext } from './hooks/useAuthContext'

//Page & components
import Home from './pages/Home'
import Login from './pages/Login'
import Signup from './pages/Signup'
import CharacterDetailsPage from './pages/CharacterDetailsPage'
import Navbar from './components/Navbar'
import CharacterPage from './pages/CharacterPage'


function App() {
  const { user } = useAuthContext()

  return (
    <div className="App">
      <BrowserRouter>
        <div className='wrapper'>
          {user && <Navbar />}
          <div className="main-content">
            <Routes>
              <Route path="/" element={user ? <Home/>: <Navigate to="/login" />}/>
              <Route path="/character/:id" element={user ? <CharacterDetailsPage/>: <Navigate to="/login" />} />
              <Route path="/login" element={!user ? <Login/>: <Navigate to="/" />}/>
              <Route path="/signup" element={!user ? <Signup/>: <Navigate to="/" />}/>
              <Route path="/characters" element={user ? <CharacterPage/>: <Navigate to="/login" />}/>
            </Routes>
          </div>
        </div>
      </BrowserRouter>
    </div>
  );
}

export default App;