import { useState } from 'react'
import { useAuthContext } from '../hooks/useAuthContext'

const CampaignForm = ({ onCampaignCreated }) => {
    const [name, setName] = useState('')
    const { user } = useAuthContext()
    const [error, setError] = useState(null)
    const [emptyFields, setEmptyFields] = useState([])

    const handleSubmit = async (e) => {
        e.preventDefault()

        if (!name) {
            setError('Campaign name is required')
            setEmptyFields(['name'])
            return
        }

        if (!user) {
            setError('You must be logged in')
            return
        }

        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/api/campaigns/create`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${user.token}`,
                },
                body: JSON.stringify({ name }),
            })

            const json = await response.json()

            if (!response.ok) {
                throw new Error(json.error || 'Failed to create campaign')
            }

            console.log('New campaign created:', json)
            onCampaignCreated(json)
            setName('')
            setError(null)
            setEmptyFields([])
        } catch (error) {
            setError(error.message)
            setEmptyFields(['name'])
        }
    }

    return (
        <form className="form card" onSubmit={handleSubmit}>
            <h3>Create a New Campaign</h3>

            <label>Campaign Name:</label>
            <input 
                className={emptyFields.includes('name') ? 'error' : ''} 
                type="text" 
                onChange={(e) => setName(e.target.value)} 
                value={name} 
                placeholder='Required'
            />

            <button>Create Campaign</button>
            {error && <div className="error">{error}</div>}
        </form>
    )
}

export default CampaignForm