import { useEffect, useState, useCallback } from 'react'
import { useAuthContext } from '../hooks/useAuthContext'
import io from 'socket.io-client'

const CampaignDetails = ({ campaignId, triggerUpdate }) => {
    const [campaign, setCampaign] = useState(null) 
    const [characters, setCharacters] = useState([])
    const { user } = useAuthContext()
    const [socket, setSocket] = useState(null) 
    const [newNpcName, setNewNpcName] = useState('')
    const [newInitiativeModifier, setNewInitiativeModifier] = useState(0)

    // Initialize the socket connection when the user is available
    useEffect(() => {
        if (user) {
            const newSocket = io(process.env.REACT_APP_API_URL, {
                query: { token: user.token },
                transports: ['websocket'],
            })

            newSocket.on('connect', () => {
                //console.log('Socket connected:', newSocket.id)
            })

            newSocket.on('connect_error', (err) => {
                console.error('Socket connection error:', err.message)
            })

            newSocket.on('error', (err) => {
                console.error('Socket error:', err.message)
            })

            newSocket.on('disconnect', () => {
                //console.log('Socket disconnected')
            })

            newSocket.on('initiativeRolled', ({ characterId, totalInitiative }) => {
                setCharacters((prevCharacters) =>
                    prevCharacters.map((char) =>
                        char._id === characterId ? { ...char, totalInitiative } : char
                    )
                )
            })

            setSocket(newSocket)

            return () => {
                newSocket.disconnect()
            }
        }
    }, [user])

    // Fetch campaign details, characters, and NPCs
    const fetchCampaignDetails = useCallback(async () => {
        if (!campaignId) {
            console.error('Campaign ID is undefined')
            return
        }

        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/api/campaigns/${campaignId}`, {
                headers: {
                    'Authorization': `Bearer ${user.token}`
                }
            })

            if (response.ok) {
                const campaignData = await response.json()
                setCampaign(campaignData); // Set the campaign details, including the name
            } else {
                console.error('Failed to fetch campaign details')
            }
        } catch (error) {
            console.error('Error fetching campaign details:', error)
        }
    }, [campaignId, user])

    const fetchCharactersAndNpcs = useCallback(async () => {
        if (!campaignId) {
            console.error('Campaign ID is undefined');
            return;
        }
    
        try {
            const [characterResponse, npcResponse] = await Promise.all([
                fetch(`${process.env.REACT_APP_API_URL}/api/campaigns/${campaignId}/characters`, {
                    headers: {
                        'Authorization': `Bearer ${user.token}`,
                    },
                }),
                fetch(`${process.env.REACT_APP_API_URL}/api/npcs/${campaignId}`, {
                    headers: {
                        'Authorization': `Bearer ${user.token}`,
                    },
                }),
            ]);
    
            if (characterResponse.ok && npcResponse.ok) {
                const charactersData = await characterResponse.json()
                const npcsData = await npcResponse.json()
    
                // Add a type to differentiate between characters and NPCs
                const charactersWithType = charactersData.map(char => ({ ...char, type: 'character' }))
                const npcsWithType = npcsData.map(npc => ({ ...npc, type: 'npc' }))
    
                // Combine and sort by initiative
                const combinedData = [...charactersWithType, ...npcsWithType].sort((a, b) => b.totalInitiative - a.totalInitiative)
                setCharacters(combinedData)
            } else {
                console.error('Failed to fetch characters or NPCs')
            }
        } catch (error) {
            console.error('Error fetching characters or NPCs:', error)
        }
    }, [campaignId, user])


    useEffect(() => {
        if (user) {
            fetchCampaignDetails()
            fetchCharactersAndNpcs()
        }

        if (user && socket) {
            const handleInitiativeRolled = ({ characterId, totalInitiative }) => {
                setCharacters((prevCharacters) =>
                    prevCharacters.map((char) =>
                        char._id === characterId ? { ...char, totalInitiative } : char
                    ).sort((a, b) => b.totalInitiative - a.totalInitiative) // Ensure sorting
                )
            }

            const handleCharacterAdded = (addedCharacter) => {
                console.log("Character: ", addedCharacter)
                
                if (addedCharacter.campaignId === campaignId) {
                    setCharacters((prevCharacters) => {
                        const newCharacter = {
                            _id: addedCharacter.characterId,
                            characterName: addedCharacter.characterName,
                            totalInitiative: addedCharacter.totalInitiative,
                            type: 'character', // Assuming it's a player character
                        }
    
                        const updatedCharacters = [...prevCharacters, newCharacter]
                        // Sort by initiative descending
                        return updatedCharacters.sort((a, b) => b.totalInitiative - a.totalInitiative)
                    })
                }
            }
    
            socket.on('character-added', handleCharacterAdded)
            socket.on('initiativeRolled', handleInitiativeRolled)
    
            return () => {
                socket.off('character-added', handleCharacterAdded)
                socket.off('initiativeRolled', handleInitiativeRolled)
            }
        }

    }, [campaignId, user, triggerUpdate, socket, fetchCampaignDetails, fetchCharactersAndNpcs])

    const handleRemoveCharacter = useCallback(
        async (characterId) => {
            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}/api/campaigns/${campaignId}/characters/${characterId}`, {
                    method: 'DELETE',
                    headers: {
                        'Authorization': `Bearer ${user.token}`,
                    },
                });
    
                if (response.ok) {
                    setCharacters((prevCharacters) => prevCharacters.filter((char) => char._id !== characterId))
                } else {
                    console.error('Failed to remove character')
                }
            } catch (error) {
                console.error('Error removing character:', error)
            }
        },
        [campaignId, user]
    )

    const handleRemoveNpc = useCallback(
        async (npcId) => {
            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}/api/npcs/${campaignId}/characters/${npcId}`, {
                    method: 'DELETE',
                    headers: {
                        'Authorization': `Bearer ${user.token}`,
                    },
                });
    
                if (response.ok) {
                    setCharacters((prevCharacters) => prevCharacters.filter((char) => char._id !== npcId))
                } else {
                    console.error('Failed to remove NPC')
                }
            } catch (error) {
                console.error('Error removing NPC:', error)
            }
        },
        [campaignId, user]
    )

    // Add a new NPC
    const handleAddNpc = useCallback(async () => {
        if (!newNpcName || !user) return;

        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/api/npcs`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${user.token}`,
                },
                body: JSON.stringify({
                    campaignId,
                    characterName: newNpcName,
                    initiativeModifier: newInitiativeModifier,
                }),
            })

            if (response.ok) {
                const newNpc = await response.json()
                setCharacters((prevCharacters) => 
                    [...prevCharacters, { ...newNpc, type: 'npc' }].sort((a, b) => b.totalInitiative - a.totalInitiative)
                )
                setNewNpcName('')
                setNewInitiativeModifier(0)
            } else {
                console.error('Failed to add NPC')
            }
        } catch (error) {
            console.error('Error adding NPC:', error)
        }
    }, [newNpcName, newInitiativeModifier, user, campaignId])

    return (
        <div className='campaign-details card'>
            <h2>{campaign ? `${campaign.name} Campaign` : 'Loading...'}</h2>
            <table>
                <thead>
                    <tr>
                        <th>Character</th>
                        <th>Initiative</th>
                    </tr>
                </thead>
                <tbody>
                    {characters.map((character) => (
                        <tr key={character._id}>
                            <td>{character.characterName}</td>
                            <td>{character.totalInitiative}</td>
                            <td>
                                {character.type === 'npc' ? (
                                    <button onClick={() => handleRemoveNpc(character._id)}>Remove NPC</button>
                                ) : (
                                    <button onClick={() => handleRemoveCharacter(character._id)}>Remove Character</button>
                                )}
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>

            <div className='form'>
                <h3>Add NPC</h3>
                <input
                    type="text"
                    placeholder="NPC Name"
                    value={newNpcName}
                    onChange={(e) => setNewNpcName(e.target.value)}
                />
                <input
                    type="number"
                    placeholder="Initiative Modifier"
                    value={newInitiativeModifier}
                    onChange={(e) => setNewInitiativeModifier(Number(e.target.value))}
                />
                <button onClick={handleAddNpc}>Add NPC</button>
            </div>
        </div>
    )
}

export default CampaignDetails
