import { useState } from 'react'
import { useSignup } from '../hooks/useSignup'
import { Link } from 'react-router-dom'

const Signup = () => {
    const [username, setusername] = useState('')
    const [password, setPassword] = useState('')
    const {signup, isLoading, error} = useSignup()

    const handleSubmit = async (e) => {
        e.preventDefault()

        await signup(username, password)
    }

    return (
        <div className='centre'>
            <div className='login'>
                <form onSubmit={handleSubmit}>
                    <h3>Sign Up</h3>

                    <input type="text" placeholder='Username' onChange={(e) => setusername(e.target.value)} value={username}/>
                    <input type="password" placeholder='Password' onChange={(e) => setPassword(e.target.value)} value={password}/>

                    <button disabled={isLoading}>Sign Up</button>
                    {error && <div className='error'>{error}</div>}
                </form>

                <p>Already have an account <Link to="/login">Log In!</Link></p>
            </div>
        </div>
    )
}

export default Signup