import { useEffect, useState } from 'react'
import { useCharactersContext } from '../hooks/useCharactersContext'
import { useAuthContext } from '../hooks/useAuthContext'


//Components
import CharacterCard from '../components/CharacterCard'
import CharacterForm from '../components/CharacterForm'
import CampaignForm from '../components/CampaignForm';
import CampaignDetails from '../components/CampaignDetails'


const Home = () => {
    const {characters, dispatch} = useCharactersContext()
    const {user} = useAuthContext()
    const [userCampaigns, setUserCampaigns] = useState([])
    const [campaigns, setCampaigns] = useState([])
    const [triggerUpdate, setTriggerUpdate] = useState(false) // This state will be used to trigger updates

    useEffect(() => {
        const fetchCharacters = async () => {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/api/characters`, {
                headers: {
                    'Authorization': `Bearer ${user.token}`
                }
            })
            const json = await response.json()

            if (response.ok) {
                dispatch({type: 'SET_CHARACTERS', payload: json})
            }
        }

        const fetchUserCampaigns = async () => {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/api/campaigns/user`, {
                headers: {
                    'Authorization': `Bearer ${user.token}`
                }
            })
            const json = await response.json()

            if (response.ok) {
                setUserCampaigns(json)
            }
        }

        const fetchCampaigns = async () => {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/api/campaigns`, {
                headers: {
                    'Authorization': `Bearer ${user.token}`
                }
            })
            const json = await response.json()

            if (response.ok) {
                setCampaigns(json)
            }
        }
        
        if (user) {
            fetchCharacters()
            fetchUserCampaigns()
            fetchCampaigns()
        }
        
    }, [dispatch, user, triggerUpdate])

    const handleCampaignCreated = (newCampaign) => {
        // Add the new campaign to the global campaigns list
        setCampaigns(prevCampaigns => [...prevCampaigns, newCampaign])

        // If the campaign belongs to the current user, add it to their campaigns as well
        if (newCampaign.user === user._id) {
            setUserCampaigns(prevUserCampaigns => [...prevUserCampaigns, newCampaign])
        }
    }

    const handleCharacterAdded = () => {
        console.log('Character added, updating triggerUpdate...')
        setTriggerUpdate(prev => !prev) // This will change the value of `triggerUpdate` and re-trigger the effect in CampaignDetails
    }
    
return (
        <>
            <div className='character-card-container'>
                {characters &&
                    characters.map((character) => (
                        <CharacterCard
                            key={character._id}
                            character={character}
                            campaigns={campaigns}
                            onCharacterAdded={handleCharacterAdded}
                        />
                    ))
                }
            </div>
            <CharacterForm />
            
            <div>
                <CampaignForm onCampaignCreated={handleCampaignCreated} />
                {userCampaigns.map((campaign) => (
                    <CampaignDetails
                        key={campaign._id}
                        campaignId={campaign._id}
                        triggerUpdate={triggerUpdate}
                    />
                ))}
            </div>
        </>
    )
}

export default Home
