import { useState } from 'react'
import { useCharactersContext } from '../hooks/useCharactersContext'
import { useAuthContext } from '../hooks/useAuthContext'

const CharacterForm = () => {
    const { dispatch } = useCharactersContext()
    const { user } = useAuthContext()

    const [characterName, setCharacterName] = useState('')
    const [health, setHealth] = useState('')
    const [level, setLevel] = useState('')
    const [initiative, setInitiative] = useState('')
    const [counterTitles, setCounterTitles] = useState('')
    const [counterValues, setCounterValues] = useState('')
    const [error, setError] = useState(null)
    const [emptyFields, setEmptyFields] = useState([])

    const handleSubmit = async (e) => {
        e.preventDefault()

        if (!user) {
            setError('You must be logged in')
            return
        }

        const character = {characterName, health, level, initiative, counterTitles, counterValues}

        const response = await fetch(`${process.env.REACT_APP_API_URL}/api/characters`, {
            method: 'POST',
            body: JSON.stringify(character),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${user.token}`
            }
        })
        const json = await response.json()

        if (!response.ok) {
            setError(json.error)
            setEmptyFields(json.emptyFields)
        }
        if (response.ok) {
            setCharacterName('')
            setHealth('')
            setLevel('')
            setInitiative('')
            setCounterTitles('')
            setCounterValues('')
            setError(null)
            setEmptyFields([])
            console.log('New character added', json)
            dispatch({type: 'CREATE_CHARACTER', payload: json})   
        }
    }

    return (
        <form className="form card" onSubmit={handleSubmit}>
            <h3>Add a New Character</h3>

            <label>Character Name:</label>
            <input className={emptyFields.includes('character name') ? 'error' : ''} type="text" onChange={(e) => setCharacterName(e.target.value)} value={characterName} placeholder='Required'/>

            <label>Character Health:</label>
            <input className={emptyFields.includes('health') ? 'error' : ''} type="number" onChange={(e) => setHealth(e.target.value)} value={health} placeholder='Required'/>

            <label>Character Level:</label>
            <input className={emptyFields.includes('level') ? 'error' : ''} type="number" onChange={(e) => setLevel(e.target.value)} value={level} placeholder='Required'/>

            <label>Character Initiative:</label>
            <input className={emptyFields.includes('initiative') ? 'error' : ''} type="number" onChange={(e) => setInitiative(e.target.value)} value={initiative} placeholder='Required'/>

            <button>Add Character</button>
            {error && <div className="error">{error}</div>}
        </form>
    )
}

export default CharacterForm;