import { useParams } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { useCharactersContext } from '../hooks/useCharactersContext'
import { useAuthContext } from '../hooks/useAuthContext'
import io from 'socket.io-client'

const CharacterDetailsPage = () => {
    const { id } = useParams()
    const { characters, dispatch } = useCharactersContext()
    const [character, setCharacter] = useState(null)
    const { user } = useAuthContext()
    const [newCounterTitle, setNewCounterTitle] = useState('')
    const [newCounterValue, setNewCounterValue] = useState(0)
    const [manualRollValue, setManualRollValue] = useState('')
    const [campaigns, setCampaigns] = useState([])
    const [campaignId, setCampaignId] = useState('')

    useEffect(() => {
        if (user && characters) {
            const foundCharacter = characters.find((char) => char._id === id);
            setCharacter(foundCharacter)
        }

        const fetchCampaigns = async () => {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/api/campaigns`, {
                headers: {
                    'Authorization': `Bearer ${user.token}`,
                },
            })
            const json = await response.json()
            if (response.ok) {
                setCampaigns(json)
            }
        };
    
        if (user) {
            fetchCampaigns()

            const newSocket = io(process.env.REACT_APP_API_URL, {
                query: { token: user.token },
                transports: ['websocket'],
            });

            newSocket.on('characterUpdated', (updatedCharacter) => {
                if (updatedCharacter._id === id) {
                    setCharacter(updatedCharacter)
                    dispatch({ type: 'UPDATE_CHARACTER', payload: updatedCharacter })
                }
            });

            return () => {
                newSocket.disconnect();
            }
        }

    }, [characters, id, user, dispatch])

    const handleAssign = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/api/campaigns/add-character`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${user.token}`,
                },
                body: JSON.stringify({
                    campaignId,
                    characterId: character._id,
                    characterName: character.characterName,
                    totalInitiative: character.totalInitiative,
                }),
            });
    
            if (response.ok) {
                console.log('Character assigned to campaign')
            } else {
                console.error('Failed to assign character to campaign')
            }
        } catch (error) {
            console.error('Error:', error)
        }
    }

    const updateCharacter = async (updatedCharacter) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/api/characters/${id}`, {
                method: 'PATCH',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${user.token}`,
                },
                body: JSON.stringify(updatedCharacter),
            });

            if (response.ok) {
                dispatch({ type: 'UPDATE_CHARACTER', payload: updatedCharacter });
            } else {
                console.error('Failed to update character');
            }
        } catch (error) {
            console.error('Error:', error);
        }
    }

    const handleUpdateDetail = async () => {
        // Make sure all required fields are provided
        if (!character.characterName || !character.health || !character.level || !character.initiative) {
            console.error('All fields must be filled out')
            return;
        }
    
        // Create the updated character object
        const updatedCharacter = {
            ...character,
            // You can include additional attributes to update here
        };
    
        try {
            // Call the function to update the character details on the server
            await updateCharacter(updatedCharacter)
            console.log('Character details updated successfully')
        } catch (error) {
            console.error('Error updating character details:', error)
        }
    }

    const rollInitiative = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/api/characters/${id}/roll-initiative`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${user.token}`,
                },
            });

            if (response.ok) {
                const updatedCharacter = await response.json();
                setCharacter(updatedCharacter);
                dispatch({ type: 'UPDATE_CHARACTER', payload: updatedCharacter });
            } else {
                console.error('Failed to roll initiative');
            }
        } catch (error) {
            console.error('Error:', error);
        }
    }

    const handleManualRoll = async () => {
        const rolledInitiative = Number(manualRollValue);
        if (!isNaN(rolledInitiative)) {
            const updatedCharacter = {
                ...character,
                rolledInitiative,
                totalInitiative: rolledInitiative + character.initiative,
            };

            setCharacter(updatedCharacter);
            updateCharacter(updatedCharacter);
        } else {
            console.error('Invalid manual roll value');
        }
    }

    const changeCounter = (index, x) => {
        if (character.counterTitles && character.counterValues) {
            const updatedCounterValues = [...character.counterValues];
            updatedCounterValues[index] += x;

            const updatedCharacter = {
                ...character,
                counterValues: updatedCounterValues,
            };

            setCharacter(updatedCharacter);
            updateCharacter(updatedCharacter);
        }
    }

    const addCounter = () => {
        const updatedCharacter = {
            ...character,
            counterTitles: [...(character.counterTitles || []), newCounterTitle],
            counterValues: [...(character.counterValues || []), newCounterValue],
        };

        setCharacter(updatedCharacter);
        updateCharacter(updatedCharacter);
        setNewCounterTitle('');
        setNewCounterValue(0);
    }

    const deleteCounter = (index) => {
        const updatedCounterTitles = character.counterTitles.filter((_, i) => i !== index);
        const updatedCounterValues = character.counterValues.filter((_, i) => i !== index);

        const updatedCharacter = {
            ...character,
            counterTitles: updatedCounterTitles,
            counterValues: updatedCounterValues,
        };

        setCharacter(updatedCharacter);
        updateCharacter(updatedCharacter);
    }

    if (!character) {
        return <div>Loading...</div>;
    }

    return (
        <>
            <div className="character-info">
                <h2>{character.characterName}</h2>
                <p>Health: {character.health}</p>
                <p>Level: {character.level}</p>
                <p>Initiative Modifier: {character.initiative}</p>
                <p>
                    Rolled Initiative: {character.rolledInitiative} + {character.initiative} ={' '}
                    {character.totalInitiative}
                </p>
                <button onClick={rollInitiative}>Roll Initiative</button>

                <div className="manual-roll">
                    <input
                        type="number"
                        placeholder="Manual Roll Value"
                        value={manualRollValue}
                        onChange={(e) => setManualRollValue(e.target.value)}
                    />
                    <button onClick={handleManualRoll}>Submit Manual Roll</button>
                </div>

                <select onChange={(e) => setCampaignId(e.target.value)} value={campaignId}>
                    <option value="">Select Campaign</option>
                    {campaigns.map((campaign) => (
                        <option key={campaign._id} value={campaign._id}>
                            {campaign.name}
                        </option>
                    ))}
                </select>
                <button onClick={handleAssign}>Assign to Campaign</button>

                

                {character.counterTitles && character.counterTitles.length > 0 ? (
                    <div className='counter'>
                        {character.counterTitles.map((title, index) => (
                            <div key={index}>
                                <div className='flex'>
                                    <p><strong>{title}:&nbsp;</strong>{character.counterValues[index]}</p>
                                </div>
                                <div className='flex'>
                                    <button onClick={() => changeCounter(index, 1)}>Increase {title}</button>
                                    <button onClick={() => changeCounter(index, -1)}>Decrease {title}</button>
                                    <button onClick={() => deleteCounter(index)}>Delete {title}</button>
                                </div>
                            </div>
                        ))}
                    </div>
                ) : null}

                 <div className="add-counter">
                    <h2>Add New Counter</h2>
                    <input
                        type="text"
                        placeholder="Title"
                        value={newCounterTitle}
                        onChange={(e) => setNewCounterTitle(e.target.value)}
                    />
                    <input
                        type="number"
                        placeholder="Initial Value"
                        value={newCounterValue}
                        onChange={(e) => setNewCounterValue(Number(e.target.value))}
                    />
                    <button onClick={addCounter}>Add Counter</button>
                </div>

                {/* Add a form to update character details */}
                <div className="update-character">
                    <h2>Update Character</h2>
                    <label>Character Name</label>
                    <input
                        type="text"
                        placeholder="Character Name"
                        value={character.characterName}
                        onChange={(e) => setCharacter({ ...character, characterName: e.target.value })}
                    />
                    <label>Health</label>
                    <input
                        type="number"
                        placeholder="Health"
                        value={character.health}
                        onChange={(e) => setCharacter({ ...character, health: Number(e.target.value) })}
                    />
                    <label>Level</label>
                    <input
                        type="number"
                        placeholder="Level"
                        value={character.level}
                        onChange={(e) => setCharacter({ ...character, level: Number(e.target.value) })}
                    />
                    <label>Initiative Modifier</label>
                    <input
                        type="number"
                        placeholder="Initiative Modifier"
                        value={character.initiative}
                        onChange={(e) => setCharacter({ ...character, initiative: Number(e.target.value) })}
                    />
                    <button onClick={handleUpdateDetail}>Update Details</button>
                </div>
            </div>
        </>
    )
}

export default CharacterDetailsPage;
