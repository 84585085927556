import { CharacterContext } from '../context/CharacterContext'
import { useContext } from 'react'

export const useCharactersContext = () => {
    const context = useContext(CharacterContext)

    if (!context) {
        throw Error('useCharacterContext must be used inside an CharactersContextProvider')
    }

    return context
}