import { Link } from 'react-router-dom'
import { useLogout } from '../hooks/useLogout'
import { useAuthContext } from '../hooks/useAuthContext'
import { useEffect } from 'react'

const Navbar = () => {
    const { logout } = useLogout()
    const { user } = useAuthContext()

    const handleClick = () => {
        logout()
    }

    useEffect(() => {
        const btn = document.querySelector('#btn')
        const sidebar = document.querySelector('.sidebar')
        const sidebarLinks = document.querySelectorAll('.sidebar a')

        // Toggle sidebar when the menu button is clicked
        if (btn) {
            btn.onclick = function () {
                sidebar.classList.toggle('active')
            }
        }

        // Handle clicks outside of the sidebar
        const handleOutsideClick = (e) => {
            if (sidebar && !sidebar.contains(e.target) && !btn.contains(e.target)) {
                sidebar.classList.remove('active') // Shrink the sidebar
            }
        }

        // Handle link clicks inside the sidebar
        sidebarLinks.forEach(link => {
            link.addEventListener('click', () => {
                sidebar.classList.remove('active') // Shrink the sidebar
            })
        })

        // Add event listener for clicks outside the sidebar
        document.addEventListener('click', handleOutsideClick)

        // Cleanup event listeners on component unmount
        return () => {
            document.removeEventListener('click', handleOutsideClick)
            sidebarLinks.forEach(link => {
                link.removeEventListener('click', () => {
                    sidebar.classList.remove('active')
                })
            })
        }
    }, [])

    return (
        <header> 
            <div className="sidebar">
                <div className="top">
                    <div className="logo">
                        <i className="bx bxl-codepen"></i>
                        <h1>FOM</h1>
                    </div>
                    <i className="bx bx-menu" id="btn"></i>
                </div>
                <div className="user">
                    <img src="user-img.jpg" alt="user" className="user-img"/>
                    <div>
                        <p className="bold">{user ? user.username : 'Guest'}</p>
                    </div>
                </div>
                <ul>
                    <li>
                        <Link to="/">
                            <i className="bx bxs-grid-alt"></i>
                            <span className="nav-item">Dashboard</span>
                        </Link>
                    </li>
                    <li>
                        <Link to="/characters">
                            <i className="bx bx-male-female"></i>
                            <span className="nav-item">Characters</span>
                        </Link>
                    </li>
                    <li>
                        <Link to="/campaigns">
                            <i className="bx bx-map-alt"></i>
                            <span className="nav-item">Campaigns</span>
                        </Link>
                    </li>
                    <li>
                        <Link to="/settings">
                            <i className="bx bx-cog"></i>
                            <span className="nav-item">Settings</span>
                        </Link>
                    </li>
                    <li>
                        {user && (
                            <div>
                                <Link to="/login" onClick={handleClick}>
                                    <i className="bx bx-log-out"></i>
                                    <span className="nav-item">Logout</span>
                                </Link>
                            </div>
                        )}
                    </li>
                </ul>
            </div>
        </header>
    )
}

export default Navbar;
