import { useEffect, useState } from 'react'
import { useCharactersContext } from '../hooks/useCharactersContext'
import { useAuthContext } from '../hooks/useAuthContext'


import CharacterCard from '../components/CharacterCard'
import CharacterForm from '../components/CharacterForm'


const CharacterPage = () => {

    const {characters, dispatch} = useCharactersContext()
    const {user} = useAuthContext()
    const [campaigns, setCampaigns] = useState([])

    useEffect(() => {
        const fetchCharacters = async () => {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/api/characters`, {
                headers: {
                    'Authorization': `Bearer ${user.token}`
                }
            })
            const json = await response.json()

            if (response.ok) {
                dispatch({type: 'SET_CHARACTERS', payload: json})
            }
        }

        const fetchCampaigns = async () => {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/api/campaigns`, {
                headers: {
                    'Authorization': `Bearer ${user.token}`
                }
            })
            const json = await response.json()

            if (response.ok) {
                setCampaigns(json)
            }
        }

        if (user) {
            fetchCharacters()
            fetchCampaigns()
        }
    }, [dispatch, user])
        


return (
        <>
            <div className='character-card-container'>
                {characters &&
                    characters.map((character) => (
                        <CharacterCard
                            key={character._id}
                            character={character}
                            campaigns={campaigns}
                            /*onCharacterAdded={handleCharacterAdded}*/
                        />
                    ))
                }
            </div>
            <CharacterForm />
        </>
    )
}

export default CharacterPage